import React, {useRef} from 'react';
import {withRouter} from 'react-router-dom';
import WishListEntry from "./WishListEntry";
import AuthService from "../../service/AuthService";
import {createStyles, Grid, makeStyles} from "@material-ui/core";
import Editable from "../ui/Editable";
import {LocalOfferOutlined, MoreVertOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) =>
    createStyles({
        listItemAction: {
            marginLeft: theme.spacing(2),
            "&:focus": {
                outline: "none",
            }
        },
        icon: {
            marginRight: theme.spacing(2),
        },
        addButton: {
            "&:focus": {
                outline: "none",
            }
        },
        wishListEntries: {
            marginTop: theme.spacing(1),
        }
    })
);

const Category = (args) => {
    const classes = useStyles();

    const {
        category,
        wishListCategoryEntries,
        filter,
        history,
        setAnchorEl,
        setCategoryActionsOpen,
        setSelectedCategory,
        setEntryActionsOpen,
        setSelectedEntry
    } = args;

    const inputRef = useRef();

    let wishListEntries;
    let allEmpty = true;
    if (wishListCategoryEntries) {
        wishListEntries = wishListCategoryEntries.map((wishListEntry, index) => {
            let entry;
            if (((filter === 'all' || filter === 'checked') && wishListEntry.checked)
                || ((filter === 'all' || filter === 'unchecked') && !wishListEntry.checked)) {
                entry = <WishListEntry
                    key={wishListEntry.id}
                    wishListEntry={wishListEntry}
                    history={history}
                    setAnchorEl={setAnchorEl}
                    setEntryActionsOpen={setEntryActionsOpen}
                    setSelectedEntry={setSelectedEntry}
                />
                allEmpty = false;
            } else {
                entry = <div key={wishListEntry.id}/>
            }
            return entry;
        });
    }

    if (AuthService.isUserLoggedOut() && allEmpty) {
        return <div key={category.id}/>
    }

    return (
        <Grid key={category.id} container item justify="center" alignItems="center" xs={12}>

            <Grid container item justify="center" alignItems="center" xs={12}>
                {AuthService.isUserLoggedIn() && category &&
                <Grid container item justify="center" alignItems="center" xs={12} className="category-title-wrapper">
                    <LocalOfferOutlined className="category-icon"/>
                    {category.name && <Editable
                        text={category.name}
                        type="input"
                        childRef={inputRef}
                        className={"list-title category-name"}
                        inputClassName={"list-title category-name"}
                        onSave={(data) => {
                            if (data !== category.name) {
                                category.name = data;

                                fetch(`/api/category/${category.id}`, {
                                    method: 'PUT',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + AuthService.getUserInfo().token
                                    },
                                    body: JSON.stringify(category),
                                }).then(() => {
                                    window.location.reload();
                                }).catch((e) => {
                                    console.error("Could not update category.", e);
                                });
                            }
                        }}
                    />}

                    <IconButton
                        aria-label="actions"
                        aria-controls="category-actions-menu"
                        aria-haspopup="true"
                        className={classes.listItemAction}
                        onClick={(event) => {
                            event.stopPropagation();
                            setAnchorEl(event.currentTarget);
                            setSelectedCategory(category);
                            setCategoryActionsOpen(true);
                        }}
                    >
                        <MoreVertOutlined/>
                    </IconButton>
                </Grid>
                }

                {!AuthService.isUserLoggedIn() &&
                <Grid container item justify="center" alignItems="center" xs={12}>
                    <div className="category-title-wrapper">
                        <LocalOfferOutlined className="category-icon"/>
                        <span className={'category-title-span'}>{category.name}</span>
                    </div>
                </Grid>
                }
            </Grid>

            <Grid container item justify="center" alignItems="center" xs={12} spacing={1}
                  className={classes.wishListEntries}>
                {wishListEntries}
            </Grid>

        </Grid>
    );
}

export default withRouter(Category);