import React, {Component} from 'react';
import './App.css';
import Contacts from "./component/contact/Contacts";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import LoginComponent from "./component/LoginComponent";
import Logout from "./component/Logout";
import ContactsList from "./component/contact/ContactsList";

class ContactsApp extends Component {

    render() {
        document.title = "Contacts";

        return (
            <Router>
                <Switch>
                    <Route path='/' exact={true} component={Contacts}/>
                    <Route path="/login" exact component={LoginComponent}/>
                    <Route path="/logout" exact component={Logout}/>
                    <Route path='/lists/:id' component={ContactsList}/>
                    <Route path='/contacts' component={ContactsList}/>
                </Switch>
            </Router>
        );
    }
}

export default ContactsApp;