import React from "react";

const Header = (args) => {

    return <div className={"header"}>
        <img alt="" {...args} />
    </div>

}

export default Header;