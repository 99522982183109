import React, {Component} from 'react';
import ContactsApp from "./ContactsApp";
import WishApp from "./WishApp";
import TodoApp from "./TodoApp";

class App extends Component {

    render() {
        const contacts = window.location.href.indexOf('contacts') > 0;
        const todos = window.location.href.indexOf('todo') > 0;

        function changeFavicon(src) {
            let link = document.createElement('link'),
                oldLink = document.getElementById('dynamic-favicon');
            link.id = 'dynamic-favicon';
            link.rel = 'shortcut icon';
            link.href = src;
            if (oldLink) {
                document.head.removeChild(oldLink);
            }
            document.head.appendChild(link);
        }

        if (contacts) {
            changeFavicon("/clogo.png");
            return <ContactsApp />;
        } else if (todos){
            changeFavicon("/tlogo.png");
            return <TodoApp />;
        } else {
            return <WishApp />;
        }
    }
}

export default App;