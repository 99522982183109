import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {AddCircleOutline, DeleteOutline, FileCopyOutlined, MoreVertOutlined} from "@material-ui/icons";
import {createStyles, makeStyles, Menu, MenuItem} from "@material-ui/core";
import ConfirmDialog from "../ui/ConfirmDialog";
import AuthService from "../../service/AuthService";

const useStyles = makeStyles((theme) =>
    createStyles({
        listItemAction: {
            marginLeft: theme.spacing(1),
            "&:focus": {
                outline: "none",
            }
        },
        actionIcon: {
            marginRight: theme.spacing(1),
        },
    })
);

const ContactsListItem = (args) => {
    const classes = useStyles();
    const {
        id,
        name,
        deleteHandler,
        setCreateDialogOpen,
        history,
    } = args;
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionsOpen, setActionsOpen] = useState(false);

    const handleDeleteList = function () {
        setConfirmOpen(false);

        fetch(`/api/contactsLists/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
        }).then((resp) => {
            console.log(resp);
            deleteHandler();
        }).catch((error) => {
            console.error(`Could not delete the contactsList with id: ${id}`);
            console.error(error);
        });
    }

    return (
        <div className="list-title">
            {id && <>
                <a href={`/lists/${id}`}>{name}</a>

                <IconButton
                    aria-label="actions"
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    className={classes.listItemAction}
                    onClick={(event) => {
                        event.stopPropagation();
                        setAnchorEl(event.currentTarget);
                        setActionsOpen(true);
                    }}
                >
                    <MoreVertOutlined/>
                </IconButton>

                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={actionsOpen}
                    onClose={() => {
                        setAnchorEl(null);
                        setActionsOpen(false);
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <MenuItem key={0} onClick={() => {
                        setActionsOpen(false);
                        fetch(`/api/contactsLists/${id}`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
                            },
                        }).then(response => response.json()).then((contactsList) => {
                            history.push(`/lists/${contactsList.id}`);
                        }).catch((error) => {
                            console.error(`Could not duplicate the contactsList with id: ${id}`);
                            console.error(error);
                        });
                    }}>
                        <FileCopyOutlined className={classes.actionIcon}/>
                        Duplizieren
                    </MenuItem>

                    <MenuItem key={1} onClick={() => {
                        setActionsOpen(false);
                        setConfirmOpen(true);
                    }}>
                        <DeleteOutline className={classes.actionIcon}/>
                        Löschen
                    </MenuItem>
                </Menu>

                <ConfirmDialog
                    handleClose={() => {
                        setConfirmOpen(false);
                    }}
                    handleConfirm={handleDeleteList}
                    open={confirmOpen}
                    title="Bestätigung erforderlich"
                    text={`Soll die Liste mit dem Namen '${name}' unwiderruflich gelöscht werden?`}/>
            </>}
            {!id &&
            <>
                <a href={`/contacts`}>{name}</a>
                <IconButton className={classes.button} onClick={() => {
                    setCreateDialogOpen(true);
                }}>
                    <AddCircleOutline/>
                </IconButton>
            </>}
        </div>
    );
};

export default ContactsListItem;
