import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Select from 'react-select';
import {Container, Form, FormGroup} from 'reactstrap';
import {Button} from "@material-ui/core";
import AuthService from "../../service/AuthService";
import CenteredMessage from "../ui/CenteredMessage";
import Footer from "../Footer";

class WishListEntryMove extends Component {

    constructor(props) {
        super(props);

        this.state = {
            wishLists: [],
            isLoading: true,
            redirect: '/' + this.props.match.params.redirect.replace('%2F', '/'),
            id: this.props.match.params.id,
            wishListId: this.props.match.url.substring(this.props.match.url.lastIndexOf('%2F') + 3)
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});

        fetch('/api/r/wishlists')
            .then(response => response.json())
            .then(data => this.setState({wishLists: data, isLoading: false}));
    }

    async handleSubmit(event) {
        event.preventDefault();

        const {wishListId, id, redirect, selectedOption} = this.state;

        await fetch(`/api/move/wishlist/${wishListId}/entry/${id}/wishlist/${selectedOption.value}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            }
        }).then(() => {
            this.props.history.push(redirect);
        });
    }

    render() {
        if (AuthService.isUserLoggedOut()) {
            return <CenteredMessage message={"Dieser Bereich ist geschützt, bitte erstmal einloggen"}>
            </CenteredMessage>
        }

        const {wishListId, wishLists, redirect} = this.state;

        const options = [];
        for (let wishList of wishLists) {
            if (parseInt(wishListId, 10) !== wishList.id) {
                options.push({ value: wishList.id, label: wishList.name });
            }
        }

        return <div>
            <Container className={'main-content'}>
                <h2>Wunschlisteneintrag verschieben nach:</h2>
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Select options={options} onChange={selectedOption => {
                            this.setState({ selectedOption });
                        }}/>
                    </FormGroup>
                    <FormGroup>
                        <Button variant="outlined" color="primary" type="submit">Speichern</Button>{' '}
                        <Button variant="outlined" href={redirect}>Abbrechen</Button>
                    </FormGroup>
                </Form>
            </Container>
            <Footer/>
        </div>
    }
}

export default withRouter(WishListEntryMove);