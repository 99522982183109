import React, {useEffect, useRef, useState} from "react";
import {
    createStyles,
    makeStyles,
    Radio,
    RadioGroup,
    FormControlLabel,
    Tooltip,
    Grid,
} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import AuthService from "../../service/AuthService";
import Footer from "../Footer";
import {FaArrowLeft} from "react-icons/fa";
import Contact from "./Contact";
import ContentEditable from 'react-contenteditable';
import Editable from "../ui/Editable";
import CustomTextField from "../ui/CustomTextField";
import IconButton from "@material-ui/core/IconButton";
import {ContactMailOutlined, PersonAddOutlined} from "@material-ui/icons";
import CreateContactDialog from "./CreateContactDialog";
import AddContactToListDialog from "./AddContactToListDialog";
import ImageGallery from "./ImageGallery";
import Header from "../Header";
import LocalStoreStateHandler from "../LocalStoreStateHandler";

const GreenRadio = withStyles({
    root: {
        color: "#9de0cb",
        '&$checked': {
            color: "#89c7b4",
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) =>
    createStyles({
        header: {
            marginBottom: theme.spacing(2),
        },
        container: {
            maxWidth: "700px",
            margin: "auto",
        },
        images: {
            margin: theme.spacing(1),
            display: "flex",
            justifyContent: "center",
        },
        allContacts: {
            fontSize: "30px",
            textAlign: "center",
            padding: theme.spacing(2),
        },
        listTitle: {
            display: "flex",
            justifyContent: "center",
            fontSize: "30px",
            fontWeight: 200,
        },
        listTitleInput: {
            textAlign: "center",
            color: "gray",
            fontWeight: 200,
        },
        description: {
            display: "flex",
            margin: theme.spacing(2),

            "& > *": {
                margin: "auto",
            }
        },
        editable: {
            padding: theme.spacing(1),
            textAlign: "center",
            fontSize: "20px",
            width: "100%",
        },
        search: {
            margin: theme.spacing(2),
            display: "flex",
            justifyContent: "center",

            "& > input": {
                border: "1px solid lightgray",
                padding: theme.spacing(1),
                color: "gray",
            }
        },
        addContact: {
            marginLeft: theme.spacing(2),
            color: "#89c7b4",

            "&:focus": {
                outline: "none",
            }
        },
        contacts: {
            marginTop: theme.spacing(2),
        },
        radios: {
            paddingLeft: theme.spacing(2),
        },
    })
);

const ContactsList = (args) => {
    const classes = useStyles();

    const {
        history,
        match,
    } = args;

    const inputRef = useRef(null);
    const localStoreStateHandler = new LocalStoreStateHandler();
    const [contactsList, setContactsList] = useState({});
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [isLongVersion, setIsLongVersion] = useState(localStoreStateHandler.getState("contactDetails", "short") === "long");
    const [isListLoading, setIsListLoading] = useState(false);
    const [createContactDialogOpen, setCreateContactDialogOpen] = useState(false);
    const [addContactToListDialogOpen, setAddContactToListDialogOpen] = useState(false);
    const [selectValue, setSelectValue] = useState(localStoreStateHandler.getState("contactDetails", "short"));
    const [expandedContactId, setExpandedContactId] = useState(-1);

    const contactsListId = match.params.id;

    useEffect(() => {

        if (AuthService.isUserLoggedOut()) {
            console.log("You need to login first");
            history.push("/login");
            return;
        }

        const url = contactsListId ? `/api/contactsLists/${contactsListId}` : "/api/contacts";

        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            }
        }).then(response => response.json())
            .then(data => {
                setContactsList(data);
                setIsListLoading(false);
                setContacts(contactsListId ? data.contacts : data);
                setFilteredContacts(contactsListId ? data.contacts : data);
            });

    }, [history, contactsListId, isListLoading]);

    function updateResults(event) {
        if (!contacts) {
            return;
        }

        const searchTerm = event.currentTarget.value.toLowerCase();
        if (searchTerm === "") {
            setFilteredContacts(contacts);
        }

        setFilteredContacts([...contacts].filter(contact => contact.name.toLowerCase().indexOf(searchTerm) !== -1 || contact.address.toLowerCase().indexOf(searchTerm) !== -1));
    }

    function handleNameChanged(newValue) {
        if (contactsList.name === newValue) {
            return;
        }

        contactsList.name = newValue;
        updateContactsList();
    }

    function handleDescriptionChanged(event) {
        if (contactsList.description === event.currentTarget.innerText) {
            return;
        }

        contactsList.description = event.currentTarget.innerText;
        updateContactsList();
    }

    function updateContactsList() {
        fetch("/api/contactsLists", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
            body: JSON.stringify(contactsList),
        }).then(response => response.json())
            .then(data => {
                setContactsList(data);
            });
    }

    function addContactToList(contact) {
        fetch(`/api/contactsLists/${contactsListId}/contact/${contact.id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
        }).then(() => {
            setIsListLoading(true);
        });
    }

    const contactsToShow = filteredContacts && filteredContacts.map(contact => (
        <Grid item xs={12} sm={6} key={contact.id}>
            <Contact
                contact={contact}
                all={isLongVersion}
                contactsListId={contactsListId}
                contactEditHandler={() => {
                    setIsListLoading(true);
                }}
                expandedContactId={expandedContactId}
                onClick={() => {
                    if (contact.id === expandedContactId) {
                        setExpandedContactId(-1);
                    } else {
                        setExpandedContactId(contact.id);
                    }
                }}
                contactRemovedHandler={() => {
                    setIsListLoading(true);
                }}/>
        </Grid>
    ));

    return (
        <>
            <div className="main-content container-fluid">
                <div className={'back'}>
                    <a href="/"><FaArrowLeft/> Zur&uuml;ck</a>
                </div>
                <Header src="/contact.png" width="350" height="160"/>
                <Grid container spacing={1} justify="center" alignItems="center" className={classes.container}>
                    {contactsListId &&
                    <>
                        <Grid item xs={12}>
                            {contactsList.name && <Editable
                                text={contactsList.name}
                                type="input"
                                childRef={inputRef}
                                className={classes.listTitle}
                                inputClassName={classes.listTitleInput}
                                onSave={handleNameChanged}
                            />}
                        </Grid>
                        <Grid item xs={12}>
                            <ImageGallery contactsList={contactsList}/>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.description}>
                                <ContentEditable
                                    className={classes.editable}
                                    tagName="div"
                                    html={(contactsList.description || "Noch keine Beschreibung vorhanden.").replaceAll("\n", "<br />")}
                                    onBlur={handleDescriptionChanged}
                                />
                            </div>
                        </Grid>
                    </>
                    }
                    {!contactsListId &&
                    <Grid item xs={12}>
                        <div className={classes.allContacts}><span>Alle Kontakte</span></div>
                    </Grid>
                    }
                    <Grid item xs={12} sm={4}>
                        <RadioGroup row className={classes.radios} value={selectValue} onChange={(event) => {
                            setSelectValue(event.target.value);
                            setIsLongVersion(event.target.value === "long");
                            localStoreStateHandler.setState("contactDetails", event.target.value);
                        }}>
                            <FormControlLabel value="short" control={<GreenRadio/>} label="Nur Name"/>
                            <FormControlLabel value="long" control={<GreenRadio/>} label="Alles"/>
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <CustomTextField
                            label="Filter"
                            variant="outlined"
                            onChange={updateResults}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Tooltip title="Neuen Kontakt anlegen">
                            <IconButton
                                className={classes.addContact}

                                onClick={() => {
                                    setCreateContactDialogOpen(true);
                                }}
                            >
                                <PersonAddOutlined/>
                            </IconButton>
                        </Tooltip>
                        <CreateContactDialog
                            open={createContactDialogOpen}
                            setOpen={setCreateContactDialogOpen}
                            contactCreated={(contact) => {
                                if (contactsListId) {
                                    addContactToList(contact);
                                }
                                setIsListLoading(true);
                            }}
                        />
                        {contactsListId &&
                        <>
                            <Tooltip title="Kontakte zur Liste hinzufügen">
                                <IconButton
                                    className={classes.addContact}
                                    onClick={() => {
                                        setAddContactToListDialogOpen(true);
                                    }}>
                                    <ContactMailOutlined/>
                                </IconButton>
                            </Tooltip>
                            <AddContactToListDialog
                                open={addContactToListDialogOpen}
                                setOpen={setAddContactToListDialogOpen}
                                contactsList={contactsList}
                                addedHandler={() => {
                                    setIsListLoading(true);
                                }}
                            />
                        </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {`Kontakte: ${filteredContacts.length}`}
                    </Grid>
                    <Grid item xs={12} className={classes.contacts}>
                        <Grid container spacing={2} alignItems="center">
                            {contactsToShow}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Footer/>
        </>
    );
};

export default ContactsList;
