import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    makeStyles, Radio,
    RadioGroup,
} from "@material-ui/core";
import React, {useState} from "react";
import AuthService from "../../service/AuthService";
import CustomTextField from "./CustomTextField";
import {withStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        name: {
            marginBottom: theme.spacing(2),
        },
    })
);

const GreenRadio = withStyles({
    root: {
        color: "#9de0cb",
        '&$checked': {
            color: "#89c7b4",
        },
    },
})((props) => <Radio color="default" {...props} />);


const CreateDialog = (args) => {
    const {
        open,
        setOpen,
        listCreated,
        title,
        url,
        noDescription,
        addPriority,
    } = args;
    const classes = useStyles();

    const initialItem = {
        name: '',
        description: '',
        priority: 100,
    };

    const [item, setItem] = useState(initialItem);

    const [priority, setPriority] = useState(100);

    const handleSubmit = function (event) {
        event.preventDefault();

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
            body: JSON.stringify(item),
        }).then(() => {
            setOpen(false);
            listCreated(true);
            setPriority(100);
            setItem(initialItem);
        }).catch((error) => {
            console.error("Could not create a new entry.");
            console.error(error);
        });
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <CustomTextField
                    className={classes.name}
                    autoFocus
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                        item.name = event.currentTarget.value
                        setItem(item);
                    }}
                />
                {!noDescription &&
                    <CustomTextField
                        id="description"
                        label="Beschreibung"
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        onChange={(event) => {
                            item.description = event.currentTarget.value
                            setItem(item);
                        }}
                    />
                }
                {addPriority && (
                    <div>
                        <RadioGroup row value={priority} onChange={(event) => {
                            const prio = parseInt(event.currentTarget.value);
                            item.priority = prio;
                            setPriority(prio);
                        }}>
                            <FormControlLabel value="1" control={<GreenRadio size="small"/>} checked={priority === 1}
                                              label="Prio 1" />
                            <FormControlLabel value="2" control={<GreenRadio size="small"/>} checked={priority === 2}
                                              label="Prio 2"/>
                            <FormControlLabel value="3" control={<GreenRadio size="small"/>} checked={priority === 3}
                                              label="Prio 3"/>
                            <FormControlLabel value="4" control={<GreenRadio size="small"/>} checked={priority === 4}
                                              label="Prio 4"/>
                            <FormControlLabel value="100" control={<GreenRadio size="small"/>} checked={priority === 100}
                                              label="Prio Normal"/>
                        </RadioGroup>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false);
                }}>
                    Abbrechen
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateDialog;
