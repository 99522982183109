import React, {Component} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import LoginComponent from "./component/LoginComponent";
import Logout from "./component/Logout";
import TodoListsOverview from "./component/todo/TodoListsOverview";
import TodoList from "./component/todo/TodoList";

class TodoApp extends Component {

    render() {
        document.title = "ListenLiebe";

        return (
            <Router>
                <Switch>
                    <Route path='/' exact={true} component={TodoListsOverview}/>
                    <Route path="/login" exact component={LoginComponent}/>
                    <Route path="/logout" exact component={Logout}/>
                    <Route path='/lists/:id' component={TodoList}/>
                </Switch>
            </Router>
        );
    }
}

export default TodoApp;