import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogContent,
    GridList,
    GridListTile,
    GridListTileBar,
    IconButton,
    makeStyles,
    Tooltip,
} from "@material-ui/core";
import {DeleteOutlineOutlined, ImageOutlined} from "@material-ui/icons";
import AuthService from "../../service/AuthService";
import ConfirmDialog from "../ui/ConfirmDialog";
import AttachmentDialog from "./AttachmentDialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        cursor: "pointer",
    },
    deleteButton: {
        "&:focus": {
            outline: "none",
        }
    },
    deleteIcon: {
        color: "#89c7b4",
    },
    addImage: {
        "&:focus": {
            outline: "none",
        }
    },
    noResult: {
        textAlign: "center",
    },
    addImageButton: {
        float: "right",
    },
    image: {
        cursor: "pointer",
    },
}));

const ImageGallery = (args) => {
    const {
        contactsList,
    } = args;
    const classes = useStyles();

    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imageDeleteConfirmDialogOpen, setImageDeleteConfirmDialogOpen] = useState(false);
    const [attachmentAdded, setAttachmentAdded] = useState(false);
    const [attachmentToDelete, setAttachmentToDelete] = useState("");
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");


    const [attachmentURLs, setAttachmentURLs] = useState([]);

    const [images, setImages] = useState(null);

    useEffect(() => {
        if (contactsList) {
            setAttachmentURLs(contactsList.attachments);
        }

    }, [contactsList]);

    function imageAddedHandler(attachmentURL) {


        fetch(`/api/contactsLists/${contactsList.id}/attachment`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
            body: JSON.stringify({attachmentURL: attachmentURL}),
        }).then(() => {
            attachmentURLs.push(attachmentURL)
            setAttachmentURLs(attachmentURLs);
            setAttachmentAdded(true);
        }).catch((error) => {
            console.error("Could not update list (adding image).");
            console.error(error);
        });
    }

    useEffect(() => {
        setAttachmentAdded(false);

        setImages(
            attachmentURLs && attachmentURLs.length > 0 ?
                (
                    <GridList className={classes.gridList} cols={2.15}>
                        {attachmentURLs.map((attachmentURL, index) => (
                            <GridListTile key={index} >
                                <img className={classes.image} src={attachmentURL} alt="" onClick={() => {
                                    setPreviewUrl(attachmentURL);
                                    setPreviewOpen(true);
                                }}/>
                                <GridListTileBar
                                    title=""
                                    classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                    }}
                                    onClick={() => {
                                        setPreviewUrl(attachmentURL);
                                        setPreviewOpen(true);
                                    }}
                                    actionIcon={
                                        <>
                                            <IconButton className={classes.deleteButton} onClick={(event) => {
                                                event.stopPropagation();
                                                setImageDeleteConfirmDialogOpen(true);
                                                setAttachmentToDelete(attachmentURL);
                                            }}>
                                                <DeleteOutlineOutlined className={classes.deleteIcon}/>
                                            </IconButton>
                                        </>
                                    }
                                />
                            </GridListTile>)
                        )}
                    </GridList>
                )
                : (
                    <p className={classes.noResult}><i>Noch keine Bilder hinzugefügt</i></p>
                ));

    }, [attachmentURLs, attachmentAdded, classes]);

    return (
        <>
            <div className={classes.images}>
                {images}
                <Dialog
                    onClose={() => {
                        setPreviewOpen(false)
                    }}
                    open={previewOpen}
                    maxWidth="md"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <img src={previewUrl} alt="" width="100%"/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setPreviewOpen(false)
                        }} color="primary" autoFocus>
                            Schließen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Tooltip title="Bild hinzufügen" className={classes.addImageButton}>
                <IconButton
                    className={classes.addImage}
                    onClick={() => {
                        setImageDialogOpen(true);
                    }}
                >
                    <ImageOutlined/>
                </IconButton>
            </Tooltip>
            <AttachmentDialog
                open={imageDialogOpen}
                setOpen={setImageDialogOpen}
                onAttachmentAdded={imageAddedHandler}
            />
            <ConfirmDialog
                handleClose={() => {
                    setImageDeleteConfirmDialogOpen(false);
                }}
                handleConfirm={() => {
                    setImageDeleteConfirmDialogOpen(false);

                    fetch(`/api/contactsLists/${contactsList.id}/attachment/`, {
                        method: 'DELETE',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + AuthService.getUserInfo().token
                        },
                        body: JSON.stringify({attachmentURL: attachmentToDelete}),
                    }).then(() => {
                        const newAttachmentURLs = attachmentURLs.filter((attachmentURL) => {
                            return attachmentURL !== attachmentToDelete;
                        });
                        setAttachmentURLs(newAttachmentURLs);
                        contactsList.attachments = newAttachmentURLs;
                    }).catch((error) => {
                        console.error("Could not delete image from list.");
                        console.error(error);
                    });

                    fetch("https://sherdt.de.cool/contacts/b.php", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: `filename=${attachmentToDelete.substring(39)}`,
                    }).catch((error) => {
                        console.error("Could not delete image from attachment service.");
                        console.error(error);
                    });
                }}
                open={imageDeleteConfirmDialogOpen}
                title="Bestätigung erforderlich"
                text={`Soll das Bild unwiderruflich gelöscht werden?`}/>
        </>
    );
};

export default ImageGallery;
