import React from 'react';
import AuthService from '../service/AuthService';
import { withRouter } from 'react-router-dom';

class Logout extends React.Component {

    componentDidMount() {

        if (AuthService.isUserLoggedIn()) {
            AuthService.logOut()
        }

        this.props.history.push('/');
    }

    render() {
        return(
            <div>
                Logout wird durchgeführt
            </div>
        )
    }

}

export default withRouter(Logout);