import React, {Component} from "react";

class LoadingAnimation extends Component {

    render() {

        // const loading = <div className={"loading-animation"}>
        //     <h3>Lade...</h3>
        // </div>

        return <div>
        </div>
    }

}

export default LoadingAnimation;