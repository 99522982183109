import axios from 'axios';
import jwt from 'jsonwebtoken';

class AuthService {

    login(credentials){
        return axios.post("/authenticate", credentials);
    }

    getUserInfo(){
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    getAuthHeader() {
        if (this.getUserInfo()) {
            return {headers: {Authorization: 'Bearer ' + this.getUserInfo().token }};
        } else {
            return null;
        }
    }

    isUserLoggedIn() {
        const userInfo = this.getUserInfo();
        if (userInfo) {
            const decodedToken = jwt.decode(userInfo.token, {complete: true});

            return decodedToken.payload.exp > new Date().getTime() / 1000;
        }

        return false;
    }

    isUserLoggedOut() {
        return !this.isUserLoggedIn();
    }

    logOut() {
        localStorage.removeItem("userInfo");
    }
}

export default new AuthService();