import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Button, Container} from '@material-ui/core';
import AuthService from '../service/AuthService';
import Footer from "./Footer";
import CustomTextField from "./ui/CustomTextField";
import {FormGroup} from "reactstrap";

class LoginComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: '',
            message: '',
        }
        this.login = this.login.bind(this);
    }

    componentDidMount() {
        if (AuthService.isUserLoggedIn()) {
            this.props.history.push('/');
        } else {
            localStorage.clear();
        }
    }

    login = (e) => {
        e.preventDefault();
        const credentials = {username: this.state.username, password: this.state.password};
        AuthService.login(credentials).then(res => {
            if(res.status === 200){
                localStorage.setItem("userInfo", JSON.stringify(res.data));
                this.props.history.push('/');
            }else {
                this.setState({message: res.data.message});
            }
        });
    };

    render() {
        return(
            <React.Fragment>
                <Container maxWidth="sm" className="main-content login">
                    <Typography variant="h4" style={styles.center}>Login</Typography>
                    <form>
                        <Typography variant="h4" style={styles.notification}>{this.state.message}</Typography>
                        <FormGroup>
                            <CustomTextField
                                className={styles.inputField}
                                autoFocus
                                id="username"
                                label="Benutzer"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={this.state.username}
                                onChange={(event) => {
                                    this.state.username = event.target.value
                                    this.setState({ "userame": event.target.value});
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <CustomTextField
                                className={styles.inputField}
                                id="password"
                                label="Passwort"
                                type="password"
                                fullWidth
                                variant="outlined"
                                value={this.state.password}
                                onChange={(event) => {
                                    this.state.password = event.target.value
                                    this.setState({ "password": event.target.value});
                                }}
                            />
                        </FormGroup>

                        <Button color="primary" onClick={this.login}>Login</Button>
                    </form>
                </Container>
                <Footer/>
            </React.Fragment>
        )
    }

}

const styles= {
    center :{
        display: 'flex',
        justifyContent: 'center',
    },
    notification: {
        display: 'flex',
        justifyContent: 'center',
        color: '#dc3545',
        marginBottom: '16px',
    }
}

export default LoginComponent;