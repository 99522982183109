import {
    Checkbox,
    Collapse,
    createStyles,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    makeStyles,
    Menu,
    MenuItem,
    Switch,
    withStyles
} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import Footer from "../Footer";
import AuthService from "../../service/AuthService";
import LoadingAnimation from "../ui/LoadingAnimation";
import Header from "../Header";
import CreateDialog from "../ui/CreateDialog";
import {
    AddCircleOutline,
    CheckBoxOutlineBlankOutlined,
    CreateOutlined,
    DeleteOutline,
    ExpandMore,
    Flag,
    FlagOutlined,
    MoreVertOutlined,
    NavigateNextOutlined
} from "@material-ui/icons";
import ConfirmDialog from "../ui/ConfirmDialog";
import {FaArrowLeft} from "react-icons/fa";
import Editable from "../ui/Editable";
import ContentEditable from "react-contenteditable";
import LocalStoreStateHandler from "../LocalStoreStateHandler";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            fontSize: "30px",
        },
        titleInput: {
            textAlign: "center",
            color: "gray",
        },
        editableListDescription: {
            textAlign: "center",
        },
        iconButton: {
            "&:focus": {
                outline: "none",
            }
        },
        category: {
            backgroundColor: "#f9f9f9",
            minWidth: "350px",
            borderBottom: "1px solid lightgray",
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: "46px"
        },
        editableCategoryWrapper: {
            width: "100%",
        },
        editableCategory: {
            display: "block",
        },
        editableDescription: {
            padding: "2px",
            fontSize: "12px",
        },
        listIcon: {
            minWidth: "36px",
            cursor: "pointer",
        },
        categoryActionButton: {
            right: "2px",
        },
        actionIcon: {
            marginRight: theme.spacing(1),
        },
        prio1: {
            color: "#b6dad2",
        },
        prio2: {
            color: "#d0d0d0",
        },
        prio3: {
            color: "#808080",
        },
        prio4: {
            color: "#f9b3b3",
        },
        todoItem: {
            paddingRight: "84px",
        },
        itemInput: {
            color: "gray",
        },
        editableSection: {
            width: "100%",
        },
        categoryTitleInput: {
            padding: "8px 2px",
            backgroundColor: "transparent",
            border: "none",//"2px solid lightgray",
            outline: "-webkit-focus-ring-color auto 1px",
            color: "gray",
            width: "100%",
        },
    })
);

const ColoredSwitch = withStyles({
    switchBase: {
        color: "#9de0cb",
        '&$checked': {
            color: "#89c7b4",
        },
        '&$checked + $track': {
            backgroundColor: "#89c7b4",
        },
    },
    checked: {},
    track: {},
})(Switch);

const GreenCheckbox = withStyles({
    root: {
        color: "#9de0cb",
        '&$checked': {
            color: "#89c7b4",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const TodoList = (args) => {
    const classes = useStyles();

    const {
        history,
        match,
    } = args;

    const todoListId = match.params.id;

    const localStoreStateHandler = new LocalStoreStateHandler();
    const inputRef = useRef(null);

    const [isTodoListLoading, setIsTodoListLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [categoryActionsOpen, setCategoryActionsOpen] = useState(false);
    const [itemActionsOpen, setItemActionsOpen] = useState(false);
    const [todoList, setTodoList] = useState({});
    const [createCategoryDialogOpen, setCreateCategoryDialogOpen] = useState(false);
    const [createItemDialogOpen, setCreateItemDialogOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [itemConfirmDialogOpen, setItemConfirmDialogOpen] = useState(false);
    const [completedItemsDeleteConfirmDialogOpen, setCompletedItemsDeleteConfirmDialogOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedItem, setSelectedItem] = useState("");
    const [categoryExpanded, setCategoryExpanded] = useState([]);
    const [updatingCollapses, setUpdatingCollapses] = useState(false);
    const [editInput, setEditInput] = useState([]);
    const [hideCompletedItems, setHideCompletedItems] = useState(localStoreStateHandler.getState("hideCompletedItems", false));

    useEffect(() => {
        setCategoryExpanded(categoryExpanded);
        setEditInput(editInput);
        setUpdatingCollapses(false);

    }, [updatingCollapses, setUpdatingCollapses, editInput, categoryExpanded]);

    useEffect(() => {
        const categories = todoList.todoCategories;

        if (!categories) {
            return;
        }

        categories.map(category => {
            categoryExpanded[category.id] = category.expanded;
            editInput[category.id] = false;

            return category;
        });

        setUpdatingCollapses(true);

    }, [todoList, categoryExpanded, editInput]);

    useEffect(() => {

        if (AuthService.isUserLoggedOut()) {
            console.log("You need to login first");
            history.push("/login");
            return;
        }

        fetch(`/api/todoLists/${todoListId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            }
        }).then(response => response.json())
            .then(data => {
                setTodoList(data);
            });

    }, [history, todoListId, isTodoListLoading]);

    const handleDeleteCategory = function () {
        setConfirmDialogOpen(false);
        if (!selectedCategory) {
            return;
        }

        fetch(`/api/todoLists/${todoListId}/category/${selectedCategory.id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
        }).then((resp) => {
            window.location.reload();
        }).catch((error) => {
            console.error(`Could not delete the category with id: ${selectedCategory.id}`);
            console.error(error);
        });
    }

    const handleDeleteItem = function () {
        setItemConfirmDialogOpen(false);
        if (!selectedItem || !selectedCategory) {
            return;
        }

        fetch(`/api/todoCategory/${selectedCategory.id}/item/${selectedItem.id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
        }).then((resp) => {
            setIsTodoListLoading(!isTodoListLoading);
        }).catch((error) => {
            console.error(`Could not delete the item with id: ${selectedItem.id}`);
            console.error(error);
        });
    }

    function updateCategory(category) {
        fetch(`/api/todoCategory`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
            body: JSON.stringify(category),
        }).catch((error) => {
            console.error(`Could not update category with id: ${category.id}`);
            console.error(error);
        });
    }

    function updateTodoList(todoList) {
        fetch(`/api/todoLists`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
            body: JSON.stringify(todoList),
        }).catch((error) => {
            console.error(`Could not update todoList with id: ${todoList.id}`);
            console.error(error);
        });
    }

    function updateItem(todoItem) {
        fetch(`/api/todoItem`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
            body: JSON.stringify(todoItem ? todoItem : selectedItem),
        }).then((resp) => {
            setIsTodoListLoading(!isTodoListLoading);
        }).catch((error) => {
            console.error(`Could not update the item with id: ${selectedItem.id}`);
            console.error(error);
        });
    }

    function uncheckAllEntriesOfCategory(category) {
        fetch(`/api/todoCategory/${category.id}/uncheckAll`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
        }).then((resp) => {
            setIsTodoListLoading(!isTodoListLoading);
        }).catch((error) => {
            console.error(`Could not uncheck all entries of category with id: ${category.id}`);
            console.error(error);
        });
    }

    function deleteCompletedTodos(category) {
        fetch(`/api/todoCategory/${category.id}/deleteCompleted`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
        }).then((resp) => {
            setIsTodoListLoading(!isTodoListLoading);
        }).catch((error) => {
            console.error(`Could not uncheck all entries of category with id: ${category.id}`);
            console.error(error);
        });
    }

    return (
        <>
            <div className="main-content container-fluid">
                <div className={'back'}>
                    <a href="/"><FaArrowLeft/> Zur&uuml;ck</a>
                </div>
                <Header src={"/todo.png"} width="350" height="104"/>
                {isTodoListLoading && <LoadingAnimation/>}
                <Grid container justify="center" alignItems="center" className={classes.container}>
                    {todoList && <Grid container item justify="center" alignItems="center" xs={12}>
                        {todoList.name && <Editable
                            text={todoList.name}
                            type="input"
                            childRef={inputRef}
                            className={classes.title}
                            inputClassName={classes.titleInput}
                            onSave={(data) => {
                                if (data !== todoList.name) {
                                    todoList.name = data;
                                    updateTodoList(todoList);
                                }
                            }}
                        />}
                    </Grid>}
                    {todoList && <Grid container item justify="center" alignItems="center" xs={12}>
                        <ContentEditable
                            className={classes.editableListDescription}
                            tagName="div"
                            html={(todoList.description || "").replaceAll("\n", "<br />")}
                            onBlur={(event) => {
                                const newDescription = event.currentTarget.innerText;
                                if (newDescription !== todoList.description) {
                                    todoList.description = newDescription;
                                    updateTodoList(todoList);
                                }
                            }}
                        />
                    </Grid>}

                    <Grid container item justify="center" alignItems="center" xs={12}>
                        <IconButton className={classes.iconButton} onClick={() => {
                            setCreateCategoryDialogOpen(true);
                        }}>
                            <AddCircleOutline/>
                        </IconButton>
                    </Grid>
                    <Grid container item component="label" justify="center" alignItems="center" xs={12} spacing={1}>
                        <Grid item>alle</Grid>
                        <Grid item>
                            <ColoredSwitch checked={hideCompletedItems} onChange={() => {
                                localStoreStateHandler.setState("hideCompletedItems", !hideCompletedItems);
                                setHideCompletedItems(!hideCompletedItems);
                            }}/>
                        </Grid>
                        <Grid item>offene</Grid>
                    </Grid>

                    {
                        todoList && todoList.todoCategories && (
                            <List>
                                {todoList.todoCategories.map((category, index) => {
                                    return <div key={index}>
                                        <ListItem className={classes.category}>
                                            <ListItemIcon className={classes.listIcon} onClick={() => {
                                                categoryExpanded[category.id] = !categoryExpanded[category.id];
                                                setUpdatingCollapses(true);
                                                category.expanded = categoryExpanded[category.id];
                                                updateCategory(category);
                                            }}>
                                                {categoryExpanded[category.id] ? <ExpandMore/> :
                                                    <NavigateNextOutlined/>}
                                            </ListItemIcon>
                                            <div className={classes.editableCategoryWrapper}>
                                                {category.name && <Editable
                                                    text={category.name}
                                                    type="input"
                                                    childRef={inputRef}
                                                    className={classes.editableCategory}
                                                    inputClassName={classes.categoryTitleInput}
                                                    onSave={(data) => {
                                                        editInput[category.id] = false;
                                                        setEditInput(editInput);
                                                        if (data !== category.name) {
                                                            category.name = data;
                                                            updateCategory(category);
                                                        }
                                                    }}
                                                />}
                                                {categoryExpanded[category.id] && category.description && category.description.trim() !== "" &&
                                                <ContentEditable
                                                    className={classes.editableDescription}
                                                    tagName="div"
                                                    html={(category.description || "").replaceAll("\n", "<br />")}
                                                    onBlur={(event) => {
                                                        const newDescription = event.currentTarget.innerText;
                                                        if (newDescription !== category.description) {
                                                            category.description = newDescription;
                                                            updateCategory(category);
                                                        }
                                                    }}
                                                />
                                                }
                                            </div>
                                            <ListItemSecondaryAction className={classes.categoryActionButton}>
                                                <IconButton
                                                    aria-label="actions"
                                                    aria-controls="category-action-menu"
                                                    aria-haspopup="true"
                                                    className={classes.iconButton}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setAnchorEl(event.currentTarget);
                                                        setSelectedCategory(category);
                                                        setCategoryActionsOpen(true);
                                                    }}
                                                >
                                                    <MoreVertOutlined/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Collapse in={categoryExpanded[category.id]} timeout="auto"
                                                  unmountOnExit>
                                            <List component="div" disablePadding>
                                                {category.todoItems && category.todoItems.map(todoItem => {
                                                    if (todoItem.completed && hideCompletedItems) {
                                                        return null;
                                                    }

                                                    return <ListItem key={`td${todoItem.id}`}
                                                                     className={classes.todoItem}>
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <GreenCheckbox
                                                                edge="start"
                                                                checked={todoItem.completed}
                                                                tabIndex={-1}
                                                                inputProps={{'aria-labelledby': todoItem.id}}
                                                                onChange={() => {
                                                                    todoItem.completed = !todoItem.completed;
                                                                    setUpdatingCollapses(true);
                                                                    setSelectedItem(todoItem);
                                                                    updateItem(todoItem);
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <div className={classes.editableItemWrapper}>
                                                            {todoItem.name && <Editable
                                                                text={todoItem.name}
                                                                type="input"
                                                                childRef={inputRef}
                                                                className={classes.item}
                                                                inputClassName={classes.itemInput}
                                                                onSave={(data) => {
                                                                    if (data !== todoItem.name) {
                                                                        todoItem.name = data;
                                                                        updateItem(todoItem);
                                                                    }
                                                                }}
                                                            />}
                                                        </div>
                                                        <ListItemSecondaryAction
                                                            className={classes.categoryActionButton}>
                                                            {todoItem.priority < 100 &&
                                                            <Flag className={classes[`prio${todoItem.priority}`]}/>
                                                            }
                                                            <IconButton
                                                                aria-label="actions"
                                                                aria-controls="item-action-menu"
                                                                aria-haspopup="true"
                                                                className={classes.iconButton}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    setAnchorEl(event.currentTarget);
                                                                    setSelectedCategory(category);
                                                                    setSelectedItem(todoItem);
                                                                    setItemActionsOpen(true);
                                                                }}
                                                            >
                                                                <MoreVertOutlined/>
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                })}
                                            </List>
                                        </Collapse>
                                    </div>
                                })}
                            </List>
                        )
                    }
                </Grid>
            </div>

            <Menu
                id="category-action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={categoryActionsOpen}
                onClose={(event) => {
                    setAnchorEl(null);
                    setCategoryActionsOpen(false);
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem key={2} onClick={() => {
                    setCategoryActionsOpen(false);
                    setCreateItemDialogOpen(true);
                }}>
                    <AddCircleOutline className={[classes.icon, classes.actionIcon].join(" ")}/>
                    Neues TODO erzeugen
                </MenuItem>

                <MenuItem key={1} onClick={() => {
                    setCategoryActionsOpen(false);
                    setConfirmDialogOpen(true);
                }}>
                    <DeleteOutline className={[classes.icon, classes.actionIcon].join(" ")}/>
                    Löschen
                </MenuItem>

                {selectedCategory && selectedCategory.todoItems.filter(todoItem => todoItem.completed).length > 1 &&
                <MenuItem key={4} onClick={() => {
                    setCategoryActionsOpen(false);
                    setCompletedItemsDeleteConfirmDialogOpen(true);
                }}>
                    <DeleteOutline className={[classes.icon, classes.actionIcon].join(" ")}/>
                    Erledigte TODOs löschen
                </MenuItem>
                }

                {selectedCategory && selectedCategory.todoItems.filter(todoItem => todoItem.completed).length > 0 &&
                <MenuItem key={3} onClick={() => {
                    setCategoryActionsOpen(false);
                    uncheckAllEntriesOfCategory(selectedCategory);
                }}>
                    <CheckBoxOutlineBlankOutlined className={[classes.icon, classes.actionIcon].join(" ")}/>
                    Alle TODOs abwählen
                </MenuItem>
                }

                {selectedCategory && selectedCategory.description === "" &&
                <MenuItem key={0} onClick={() => {
                    setCategoryActionsOpen(false);
                    selectedCategory.description = "Beschreibung";
                    updateCategory(selectedCategory);
                }}>
                    <CreateOutlined className={[classes.icon, classes.actionIcon].join(" ")}/>
                    Beschreibung hinzufügen
                </MenuItem>
                }
            </Menu>

            <Menu
                id="item-action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={itemActionsOpen}
                onClose={(event) => {
                    setAnchorEl(null);
                    setItemActionsOpen(false);
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem key={1} onClick={() => {
                    setItemActionsOpen(false);
                    setItemConfirmDialogOpen(true);
                }}>
                    <DeleteOutline className={[classes.icon, classes.actionIcon].join(" ")}/>
                    Löschen
                </MenuItem>
                <MenuItem key={2} onClick={() => {
                    setItemActionsOpen(false);
                    selectedItem.priority = 1;
                    updateItem();
                }}>
                    <Flag className={[classes.icon, classes.actionIcon, classes.prio1].join(" ")}/>
                    Prio 1
                </MenuItem>
                <MenuItem key={3} onClick={() => {
                    setItemActionsOpen(false);
                    selectedItem.priority = 2;
                    updateItem();
                }}>
                    <Flag className={[classes.icon, classes.actionIcon, classes.prio2].join(" ")}/>
                    Prio 2
                </MenuItem>
                <MenuItem key={4} onClick={() => {
                    setItemActionsOpen(false);
                    selectedItem.priority = 3;
                    updateItem();
                }}>
                    <Flag className={[classes.icon, classes.actionIcon, classes.prio3].join(" ")}/>
                    Prio 3
                </MenuItem>
                <MenuItem key={5} onClick={() => {
                    setItemActionsOpen(false);
                    selectedItem.priority = 4;
                    updateItem();
                }}>
                    <Flag className={[classes.icon, classes.actionIcon, classes.prio4].join(" ")}/>
                    Prio 4
                </MenuItem>
                <MenuItem key={6} onClick={() => {
                    setItemActionsOpen(false);
                    selectedItem.priority = 100;
                    updateItem();
                }}>
                    <FlagOutlined className={[classes.icon, classes.actionIcon].join(" ")}/>
                    Prio Normal
                </MenuItem>
            </Menu>

            <CreateDialog
                open={createCategoryDialogOpen}
                setOpen={setCreateCategoryDialogOpen}
                listCreated={() => {
                    window.location.reload();
                }}
                title="Neue Kategorie erstellen"
                url={`/api/todoLists/${todoListId}/category/`}
            />

            <CreateDialog
                open={createItemDialogOpen}
                setOpen={setCreateItemDialogOpen}
                listCreated={() => {
                    setIsTodoListLoading(!isTodoListLoading);
                }}
                noDescription
                addPriority
                title="Neues TODO erstellen"
                url={`/api/todoCategory/${selectedCategory.id}/item/`}
            />

            <ConfirmDialog
                handleClose={() => {
                    setConfirmDialogOpen(false);
                }}
                handleConfirm={handleDeleteCategory}
                open={confirmDialogOpen}
                title="Bestätigung erforderlich"
                text={`Soll die Kategorie mit dem Namen '${selectedCategory.name}' unwiderruflich gelöscht werden?`}/>

            <ConfirmDialog
                handleClose={() => {
                    setItemConfirmDialogOpen(false);
                }}
                handleConfirm={handleDeleteItem}
                open={itemConfirmDialogOpen}
                title="Bestätigung erforderlich"
                text={`Soll das TODO '${selectedItem.name}' unwiderruflich gelöscht werden?`}/>

            <ConfirmDialog
                handleClose={() => {
                    setCompletedItemsDeleteConfirmDialogOpen(false);
                }}
                handleConfirm={() => {
                    deleteCompletedTodos(selectedCategory);
                    setCompletedItemsDeleteConfirmDialogOpen(false);
                }}
                open={completedItemsDeleteConfirmDialogOpen}
                title="Bestätigung erforderlich"
                text={`Sollen alle erledigten TODOs von '${selectedCategory.name}' unwiderruflich gelöscht werden?`}/>

            <Footer/>
        </>
    );
};

export default TodoList;
