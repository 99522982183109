import React, {useEffect, useRef, useState} from 'react';
import parser from 'bbcode-to-react';
import {Container} from 'reactstrap';
import Category from "./Category";
import Header from "../Header";
import LoadingAnimation from "../ui/LoadingAnimation";
import AuthService from "../../service/AuthService";
import {FaArrowLeft} from 'react-icons/fa';
import {
    createStyles,
    FormControlLabel,
    Grid,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    Tooltip
} from "@material-ui/core";
import Editable from "../ui/Editable";
import ContentEditable from "react-contenteditable";
import {withStyles} from "@material-ui/core/styles";
import {
    AddCircleOutline,
    CardGiftcardOutlined,
    CreateOutlined,
    DeleteOutline,
    TrendingFlatOutlined
} from "@material-ui/icons";
import CreateDialog from "../ui/CreateDialog";
import ConfirmDialog from "../ui/ConfirmDialog";
import Footer from "../Footer";
import LocalStoreStateHandler from "../LocalStoreStateHandler";
import {GotoTag} from "./GotoTag";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            fontSize: "30px",
            fontWeight: 200,
        },
        titleInput: {
            textAlign: "center",
            color: "gray",
            fontWeight: 200,
        },
        radios: {
            marginTop: theme.spacing(3),
            "& .MuiFormControlLabel-label": {
                fontSize: "14px",
            }
        }
    })
);

const GreenRadio = withStyles({
    root: {
        color: "#9de0cb",
        '&$checked': {
            color: "#89c7b4",
        },
    },
})((props) => <Radio color="default" {...props} />);

const WishList = (args) => {
    const classes = useStyles();
    const {
        match,
        history,
    } = args;

    const redirect = '/' + match.url.substring(1).replace('/', '%2F');
    const localStoreStateHandler = new LocalStoreStateHandler();

    const inputRef = useRef(null);
    const [wishList, setWishList] = useState({});
    const [allCategories, setAllCategories] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [categoryActionsOpen, setCategoryActionsOpen] = useState(false);
    const [entryActionsOpen, setEntryActionsOpen] = useState(false);
    const [entryConfirmDialogOpen, setEntryConfirmDialogOpen] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState({});
    const [categoryConfirmDialogOpen, setCategoryConfirmDialogOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [isLoadingWishList, setIsLoadingWishList] = useState(true);
    const [isLoadingCategory, setIsLoadingCategory] = useState(false);
    const [createCategoryDialogOpen, setCreateCategoryDialogOpen] = useState(false);
    const [filter, setFilter] = useState(localStoreStateHandler.getState("wishListFilter", "unchecked"));

    parser.registerTag('goto', GotoTag);

    useEffect(() => {
        fetch('/api/r/categories')
            .then(response => response.json())
            .then(data => {
                setAllCategories(data);
                setIsLoadingCategory(false);
            });

    }, [match, isLoadingCategory]);

    useEffect(() => {
        fetch('/api/r/wishlist/' + match.params.id)
            .then(response => response.json())
            .then(data => {
                setWishList(data);
                setIsLoadingWishList(false);
            });

    }, [match]);

    const {entries} = wishList;

    if (isLoadingCategory || isLoadingWishList || !entries) {
        return <LoadingAnimation/>
    }

    const categories = entries.reduce((groups, item) => {
        const group = (groups[item.category.id] || []);
        group.push(item);
        groups[item.category.id] = group;
        return groups;
    }, {});

    const updateWishList = function(wishList) {
        fetch(`/api/wishlist/${wishList.id}`, {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + AuthService.getUserInfo().token
            },
            body: JSON.stringify(wishList),
        }).catch((e) => {
            console.error("Could not update wishList: ", e);
        });
    }

    const deleteCategory = function (id) {
        if (AuthService.isUserLoggedOut()) {
            return
        }

        fetch(`/api/category/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            }
        }).then(() => {
            window.location.reload();
        }).catch((e) => {
            console.error(`Could not delete category. `, e);
        });
    }

    const deleteEntry = function (id) {
        if (AuthService.isUserLoggedOut()) {
            return
        }

        fetch(`/api${match.url}/entry/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            }
        }).then(() => {
            window.location.reload();
        }).catch(e => {
            console.error("Could not delete wishListEntry.", e);
        });
    }

    return (
        <div>

            <Container fluid className={'main-content'}>
                <div className={'back'}>
                    <a size="sm" href="/"><FaArrowLeft/> Zur&uuml;ck</a>
                </div>

                <Header src={"/logo-wl.png"} width="350" height="98"/>

                <Grid container justify="center" alignItems="center">

                    {AuthService.isUserLoggedIn() && wishList &&
                    <Grid container item justify="center" alignItems="center" xs={12}>
                        {wishList.name && <Editable
                            text={wishList.name}
                            type="input"
                            childRef={inputRef}
                            className={classes.title}
                            inputClassName={classes.titleInput}
                            onSave={(data) => {
                                if (data !== wishList.name) {
                                    wishList.name = data;

                                    updateWishList(wishList);
                                }
                            }}
                        />}
                    </Grid>
                    }
                    {!AuthService.isUserLoggedIn() &&
                    <Grid container item justify="center" alignItems="center" xs={12}>
                        <div className="list-title">
                            {wishList.name}
                        </div>
                    </Grid>
                    }

                    {AuthService.isUserLoggedIn() && <Grid container item justify="center" alignItems="center" xs={12}>
                        <ContentEditable
                            className="wish-list-description display-linebreak"
                            tagName="div"
                            html={(wishList.description || "noch keine Beschreibung vorhanden").replaceAll("\n", "<br />")}
                            onBlur={(event) => {
                                const newDescription = event.currentTarget.innerText.replaceAll("&nbsp;", " ");
                                if (newDescription !== wishList.description && newDescription.trim() !== "noch keine Beschreibung vorhanden") {
                                    wishList.description = newDescription;

                                    updateWishList(wishList);
                                }
                            }}
                        />
                    </Grid>}

                    {!AuthService.isUserLoggedIn() && wishList.description &&
                    <Grid container item justify="center" alignItems="center" xs={12} className="to-shop">
                        <div className="wish-list-description display-linebreak">{parser.toReact(wishList.description ? wishList.description : "")}</div>
                    </Grid>
                    }

                    <Grid container item justify="center" alignItems="center" xs={12}>
                        <RadioGroup row className={classes.radios} value={filter} onChange={(event) => {
                            setFilter(event.target.value);
                            localStoreStateHandler.setState("wishListFilter", event.target.value);
                        }}>
                            <FormControlLabel value="checked" control={<GreenRadio size="small"/>}
                                              label="Wunsch erfüllt"/>
                            <FormControlLabel value="unchecked" control={<GreenRadio size="small"/>}
                                              label="Wunsch offen"/>
                            <FormControlLabel value="all" control={<GreenRadio size="small"/>} label="Alle Wünsche"/>
                        </RadioGroup>
                    </Grid>

                    {AuthService.isUserLoggedIn() &&
                    <Grid container item justify="center" alignItems="center" xs={12}>
                        <Tooltip title="Kategorie hinzufügen">
                            <IconButton className="iconButtonNoOutline" onClick={() => {
                                setCreateCategoryDialogOpen(true);
                                // history.push(`/category/new/wishlist%2F${match.params.id}`);
                            }}>
                                <AddCircleOutline/>
                            </IconButton>
                        </Tooltip>

                        <CreateDialog
                            open={createCategoryDialogOpen}
                            setOpen={setCreateCategoryDialogOpen}
                            listCreated={() => {
                                setIsLoadingCategory(true);
                            }}
                            noDescription
                            title="Neue Kategorie erstellen"
                            url={`/api/category`}
                        />
                    </Grid>
                    }

                    <Grid container item justify="center" alignItems="center" xs={12}>
                        {allCategories && allCategories.map && allCategories.map((category, index) => {
                            const wishListCategoryEntries = categories[category.id];
                            if (AuthService.isUserLoggedOut() && !wishListCategoryEntries) {
                                return <div key={category.id}></div>
                            }

                            return <Category key={category.id}
                                             category={category}
                                             wishListCategoryEntries={wishListCategoryEntries}
                                             filter={filter}
                                             setAnchorEl={setAnchorEl}
                                             setCategoryActionsOpen={setCategoryActionsOpen}
                                             setSelectedCategory={setSelectedCategory}
                                             setEntryActionsOpen={setEntryActionsOpen}
                                             setSelectedEntry={setSelectedEntry}
                            />
                        })}
                    </Grid>

                    {AuthService.isUserLoggedIn() &&

                    <>
                        <Menu
                            id="category-actions-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={categoryActionsOpen}
                            onClose={(event) => {
                                setAnchorEl(null);
                                setCategoryActionsOpen(false);
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem key={0} onClick={() => {
                                setCategoryActionsOpen(false);
                                history.push(`/entry/new${redirect}/${selectedCategory.id}`);
                            }}>
                                <CardGiftcardOutlined className="actionMenuIcon"/>
                                Wunsch hinzufügen
                            </MenuItem>

                            <MenuItem key={1} onClick={() => {
                                setCategoryActionsOpen(false);
                                setCategoryConfirmDialogOpen(true);
                            }}>
                                <DeleteOutline className="actionMenuIcon"/>
                                Löschen
                            </MenuItem>
                        </Menu>

                        <ConfirmDialog
                            handleClose={() => {
                                setCategoryConfirmDialogOpen(false);
                            }}
                            handleConfirm={() => {
                                deleteCategory(selectedCategory.id);
                            }}
                            open={categoryConfirmDialogOpen}
                            title="Bestätigung erforderlich"
                            text={`Soll die Kategorie mit dem Namen '${selectedCategory.name}' unwiderruflich gelöscht werden?`}/>

                        <Menu
                            id="entry-actions-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={entryActionsOpen}
                            onClose={(event) => {
                                setAnchorEl(null);
                                setEntryActionsOpen(false);
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem key={0} onClick={() => {
                                setEntryActionsOpen(false);
                                history.push(`/entry/${selectedEntry.id}/${match.url.substring(1).replace('/', '%2F')}/${selectedEntry.category.id}`);
                            }}>
                                <CreateOutlined className="actionMenuIcon"/>
                                Bearbeiten
                            </MenuItem>

                            <MenuItem key={1} onClick={() => {
                                setEntryActionsOpen(false);
                                history.push(`/move/${selectedEntry.id}/${match.url.substring(1).replace('/', '%2F')}`);
                            }}>
                                <TrendingFlatOutlined className="actionMenuIcon"/>
                                Verschieben
                            </MenuItem>

                            <MenuItem key={2} onClick={() => {
                                setEntryActionsOpen(false);
                                setEntryConfirmDialogOpen(true);
                            }}>
                                <DeleteOutline className="actionMenuIcon"/>
                                Löschen
                            </MenuItem>
                        </Menu>

                        <ConfirmDialog
                            handleClose={() => {
                                setEntryConfirmDialogOpen(false);
                            }}
                            handleConfirm={() => {
                                setEntryConfirmDialogOpen(false);
                                deleteEntry(selectedEntry.id);
                            }}
                            open={entryConfirmDialogOpen}
                            title="Bestätigung erforderlich"
                            text={`Soll der Wunsch mit dem Namen '${selectedEntry.name}' unwiderruflich gelöscht werden?`}/>
                    </>
                    }

                </Grid>
            </Container>
            <Footer/>
        </div>
    );
}

export default WishList;