import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Container, Form, FormGroup} from 'reactstrap';
import {Button} from "@material-ui/core";
import AuthService from "../../service/AuthService";
import CenteredMessage from "../ui/CenteredMessage";
import Footer from "../Footer";
import CustomTextField from "../ui/CustomTextField";

class WishListEntryEdit extends Component {

    emptyItem = {
        name: '',
        link: '',
        imageLink: '',
        checked: false,
        category: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            redirect: '/' + this.props.match.params.redirect.replace('%2F', '/')
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const {redirect} = this.state;

        if (this.props.match.params.id !== 'new') {
            const entry = await (await fetch(`/api/r${redirect}/entry/${this.props.match.params.id}`)).json();
            this.setState({item: entry});
        } else {

            this.emptyItem.category = await (await fetch(`/api/r/category/${this.props.match.params.categoryId}`)).json();
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item, redirect} = this.state;

        await fetch("/api" + redirect + '/entry', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
            body: JSON.stringify(item),
        });

        this.props.history.push(this.state.redirect);
    }

    render() {
        if (AuthService.isUserLoggedOut()) {
            return <CenteredMessage message={"Dieser Bereich ist geschützt, bitte erstmal einloggen"}>
            </CenteredMessage>
        }

        const {item, redirect} = this.state;
        const title = <h2>{item.id ? 'Wunschlisteneintrag ändern' : 'Neuer Wunschlisten Eintrag'}</h2>;

        return <div>
            <Container className={'main-content'}>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <CustomTextField
                            className="margin-top"
                            autoFocus
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={item.name}
                            onChange={(event) => {
                                item.name = event.target.value;
                                this.setState({item});
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <CustomTextField
                            id="link"
                            label="Link"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={item.link}
                            onChange={(event) => {
                                item.link = event.target.value;
                                this.setState({item});
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <CustomTextField
                            id="imageLink"
                            label="Link zum Bild"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={item.imageLink}
                            onChange={(event) => {
                                item.imageLink = event.target.value;
                                this.setState({item});
                            }}/>
                        {item.imageLink &&
                        <img alt={''} width={200} id={'linkedImage'} src={item.imageLink} className="margin-top"/>
                        }
                    </FormGroup>
                    <FormGroup>
                        <Button variant="outlined" color="primary" type="submit">Speichern</Button>{' '}
                        <Button variant="outlined" href={redirect}>Abbrechen</Button>
                    </FormGroup>
                </Form>
            </Container>
            <Footer/>
        </div>
    }
}

export default withRouter(WishListEntryEdit);