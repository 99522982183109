import {createStyles, Grid, makeStyles, Menu, MenuItem} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Footer from "../Footer";
import AuthService from "../../service/AuthService";
import LoadingAnimation from "../ui/LoadingAnimation";
import Header from "../Header";
import CreateDialog from "../ui/CreateDialog";
import IconButton from "@material-ui/core/IconButton";
import {AddCircleOutline, DeleteOutline, FileCopyOutlined, MoreVertOutlined} from "@material-ui/icons";
import ConfirmDialog from "../ui/ConfirmDialog";

const useStyles = makeStyles((theme) =>
    createStyles({
        listItemAction: {
            marginLeft: theme.spacing(2),
            "&:focus": {
                outline: "none",
            }
        },
        icon: {
            marginRight: theme.spacing(2),
        },
        addButton: {
            "&:focus": {
                outline: "none",
            }
        },
        listTitleWrapper: {
            marginTop: theme.spacing(2),

            "& .list-title": {
                marginLeft: "48px",
            },
        },
    })
);

const TodoListsOverview = (args) => {
    const classes = useStyles();

    const {
        history,
    } = args;

    const [actionsOpen, setActionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [todoLists, setTodoLists] = useState([]);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [isLoadingTodoLists, setIsLoadingTodoLists] = useState(true);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedList, setSelectedList] = useState("");


    useEffect(() => {

        if (AuthService.isUserLoggedOut()) {
            console.log("You need to login first, redirecting you to login page.");
            history.push("/login");
            return;
        }

        fetch(`/api/todoLists`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            }
        }).then(response => response.json())
            .then((data) => {
                setTodoLists(data);
                setIsLoadingTodoLists(false);
            }).catch((e) => {
                console.error("Could not fetch todoLists.", e);
        });

    }, [history, isLoadingTodoLists]);

    const handleDeleteList = function () {
        setConfirmDialogOpen(false);
        if (!selectedList) {
            return;
        }

        fetch(`/api/todoLists/${selectedList.id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
        }).then(() => {
            setIsLoadingTodoLists(true);
        }).catch((error) => {
            console.error(`Could not delete the todoList with id: ${selectedList.id}`);
            console.error(error);
        });
    }

    const handleDuplicateList = function () {
        fetch(`/api/todoLists/${selectedList.id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
        }).then(response => response.json()).then((todoList) => {
            history.push(`/lists/${todoList.id}`);
        }).catch((error) => {
            console.error(`Could not duplicate the todoList with id: ${selectedList.id}`);
            console.error(error);
        });
    }

    return (
        <>
            <div className="main-content container-fluid">
                <Header src={"/todo.png"} width="350" height="104"/>
                {isLoadingTodoLists && <LoadingAnimation/>}
                <Grid container justifyContent="center" alignItems="center" className={classes.listTitleWrapper}>
                    {
                        todoLists.map(todoList => {
                            return <Grid item xs={12} key={todoList.id} >
                                <div className="list-title">
                                    <a href={`/lists/${todoList.id}`}>{todoList.name}</a>

                                    <IconButton
                                        aria-label="actions"
                                        aria-controls="actions-menu"
                                        aria-haspopup="true"
                                        className={classes.listItemAction}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setAnchorEl(event.currentTarget);
                                            setSelectedList(todoList);
                                            setActionsOpen(true);
                                        }}
                                    >
                                        <MoreVertOutlined/>
                                    </IconButton>

                                </div>
                            </Grid>
                        })
                    }
                    <Grid container item justifyContent="center" alignItems="center" xs={12}>
                        <IconButton className={classes.addButton} onClick={() => {
                            setCreateDialogOpen(true);
                        }}>
                            <AddCircleOutline/>
                        </IconButton>
                    </Grid>
                </Grid>
            </div>

            <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                keepMounted
                open={actionsOpen}
                onClose={() => {
                    setAnchorEl(null);
                    setActionsOpen(false);
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem key={0} onClick={() => {
                    setActionsOpen(false);
                    handleDuplicateList();
                }}>
                    <FileCopyOutlined className={classes.icon}/>
                    Duplizieren
                </MenuItem>

                <MenuItem key={1} onClick={() => {
                    setActionsOpen(false);
                    setConfirmDialogOpen(true);
                }}>
                    <DeleteOutline className={classes.icon}/>
                    Löschen
                </MenuItem>
            </Menu>

            <CreateDialog
                open={createDialogOpen}
                setOpen={setCreateDialogOpen}
                listCreated={setIsLoadingTodoLists}
                title="Neue Liste erstellen"
                url="/api/todoLists"
            />

            <ConfirmDialog
                handleClose={() => {
                    setConfirmDialogOpen(false);
                }}
                handleConfirm={handleDeleteList}
                open={confirmDialogOpen}
                title="Bestätigung erforderlich"
                text={`Soll die Liste mit dem Namen '${selectedList.name}' unwiderruflich gelöscht werden?`}/>

            <Footer/>
        </>
    );
};

export default TodoListsOverview;
