import {
    createStyles,
    makeStyles,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {PersonOutlineOutlined} from "@material-ui/icons";
import AuthService from "../../service/AuthService";

const useStyles = makeStyles((theme) =>
    createStyles({
        name: {
            marginBottom: theme.spacing(2),
        },
        icon: {
            marginRight: theme.spacing(1),
            color: "#89c7b4",
        }
    })
);

const AddContactToListDialog = (args) => {
    const {
        open,
        setOpen,
        contactsList,
        addedHandler,
    } = args;
    const classes = useStyles();

    const [contactsFromQuery, setContactsFromQuery] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState();

    useEffect(() => {
        setSelectedOptions(contactsList.contacts);
    }, [contactsList]);

    const handleSubmit = function (event) {
        event.preventDefault();

        fetch(`/api/contactsLists/${contactsList.id}/contact`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
            body: JSON.stringify(selectedOptions),
        }).then(() => {
            setOpen(false);
            addedHandler(selectedOptions);
        }).catch((error) => {
            console.error("Could not create a new list.");
            console.error(error);
        });
    }

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">Kontakte zur Liste hinzufügen</DialogTitle>
            <DialogContent>
                <Autocomplete
                    multiple
                    id="contacts"
                    noOptionsText={"Keine Kontakte gefunden"}
                    options={contactsFromQuery}
                    renderOption={(option => (
                        <>
                            <PersonOutlineOutlined className={classes.icon}/>
                            {option.name}
                        </>
                    ))}
                    getOptionLabel={(option) => option.name}
                    // defaultValue={}
                    onChange={(e, val) => {
                        setSelectedOptions(val);
                    }}
                    onInputChange={(e, name) => {
                        if (name.length === 0) {
                            return;
                        }

                        fetch(`/api/contacts/search?query=${encodeURIComponent(name)}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
                            },
                        }).then(response => response.json()).then(foundContacts => {

                            let result = [];
                            for (let fi in foundContacts) {
                                const foundContact = foundContacts[fi];
                                let alreadyInList = false;
                                for (let ci in selectedOptions) {
                                    const selectedOption = selectedOptions[ci];
                                    if (foundContact.id === selectedOption.id) {
                                        alreadyInList = true;
                                        break;
                                    }
                                }
                                if (!alreadyInList) {
                                    result.push(foundContact);
                                }
                            }

                            setContactsFromQuery(result);
                        }).catch((error) => {
                            console.error("Could not find contacts by name.");
                            console.error(error);
                        });
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Verfügbare Kontakte"
                            />
                        )
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false);
                }}>
                    Abbrechen
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddContactToListDialog;
