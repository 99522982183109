import React, {Component} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Wish from './component/wish/Wish';
import WishList from './component/wish/WishList';
import WishListEdit from './component/wish/WishListEdit';
import WishListEntryEdit from './component/wish/WishListEntryEdit';
import WishListEntryMove from './component/wish/WishListEntryMove';
import LoginComponent from "./component/LoginComponent";
import Logout from "./component/Logout";

class WishApp extends Component {

    render() {
        document.title = "Wunscherfüller";

        return (
            <Router>
                <Switch>
                    <Route path='/' exact={true} component={Wish}/>
                    <Route path="/login" exact component={LoginComponent}/>
                    <Route path="/logout" exact component={Logout}/>
                    <Route path='/wishlist/:id' component={WishList}/>
                    <Route path='/wishlists/:id' component={WishListEdit}/>
                    <Route path='/entry/:id/:redirect/:categoryId' component={WishListEntryEdit}/>
                    <Route path='/move/:id/:redirect' component={WishListEntryMove}/>
                </Switch>
            </Router>
        )
    }
}

export default WishApp;