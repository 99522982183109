class LocalStoreStateHandler {

    STATE_KEY = "lastUserState";

    setState(key, value) {
        const userStateStringed = localStorage.getItem(this.STATE_KEY);
        let userState = userStateStringed ? JSON.parse(userStateStringed) : {};
        userState[key] = value;
        localStorage.setItem(this.STATE_KEY, JSON.stringify(userState));
    }

    getState(key, defaultValue) {
        let userState = localStorage.getItem(this.STATE_KEY) || "{}";
        userState = JSON.parse(userState);
        if (userState[key]) {
            return userState[key];
        }

        return defaultValue;
    }
}

export default LocalStoreStateHandler;