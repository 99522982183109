import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import AuthService from "../../service/AuthService";
import {
    Checkbox,
    createStyles,
    FormControlLabel,
    Grid,
    makeStyles,
    Paper,
    Typography,
    withStyles
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {MoreVertOutlined} from "@material-ui/icons";
import ConfirmDialog from "../ui/ConfirmDialog";

const useStyles = makeStyles((theme) =>
    createStyles({
        itemAction: {
            "&:focus": {
                outline: "none",
            }
        },
        paper: {
            padding: theme.spacing(2),
            width: "450px",
        },
        image: {
            width: "250px",
        },
        wrap: {
            overflowWrap: "break-word",
        },
        itemDescription: {
            paddingTop: "8px",
            paddingLeft: 0,
        },
        description: {
            alignItems: "center",
        },
        itemDescriptionAdmin: {
            paddingTop: "8px",
        },
        "@media (min-width: 600px)": {
            itemDescription: {
                paddingTop: 0,
                paddingLeft: "8px",
            },
            description: {
                alignItems: "flex-start",
            },
            itemDescriptionAdmin: {
                paddingLeft: "24px",
                paddingTop: 0,
            },
            image: {
                width: "200px",
            },
        },
    })
);

const GreenCheckbox = withStyles({
    root: {
        color: "#9de0cb",
        '&$checked': {
            color: "#89c7b4",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const WishListEntry = (args) => {

    const classes = useStyles();
    const {
        setAnchorEl,
        setEntryActionsOpen,
        setSelectedEntry,
        wishListEntry,
        match
    } = args;
    const [entryCompletedConfirmDialogOpen, setEntryCompletedConfirmDialogOpen] = useState(false);
    const [newCheckboxValue, setNewCheckboxValue] = useState(false);

    const checkboxHandler = function () {
        wishListEntry.checked = newCheckboxValue;

        fetch(`/api/r${match.url}/entry/${wishListEntry.id}/checked`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(wishListEntry)
        }).then(response => response.json())
            .then(() => {
                window.location.reload();
            }).catch(e => {
            console.error("Could not update wishListEntry.", e);
        });
    }

    return (
        <Grid key={wishListEntry.id} container item justify="center" alignItems="center" xs={12}>
            <Paper elevation={2} className={classes.paper}>
                <Grid key={wishListEntry.id} container item xs={12}>

                    <Grid container item xs={AuthService.isUserLoggedIn() ? 11 : 12}>
                        <Grid container item justify="center" alignItems="flex-start" xs={12} sm={6}>
                            {wishListEntry.link ?
                                (<a key={wishListEntry.id} href={wishListEntry.link} target={'_blank'}
                                    rel="noopener noreferrer">
                                    <img alt={''} id={'linkedImage'} src={wishListEntry.imageLink}
                                         className={classes.image}/>
                                </a>) :
                                (<img alt={''} id={'linkedImage'} src={wishListEntry.imageLink}
                                      className={classes.image}/>)}
                        </Grid>

                        <Grid container item justify="flex-start" alignItems="flex-start" xs={12} sm={6}
                              className={AuthService.isUserLoggedIn() ? classes.itemDescriptionAdmin : classes.itemDescription}>
                            <Grid container direction="column" justify="flex-start" alignItems="flex-start"
                                  className={classes.description} wrap="nowrap">
                                <Grid item xs={12}>
                                    <Typography className={classes.wrap}>{wishListEntry.name}</Typography>
                                </Grid>

                                {wishListEntry.link && (
                                    <Grid className="to-shop" item xs={12}>
                                        <a key={'1_' + wishListEntry.id} href={wishListEntry.link}
                                           target={'_blank'} rel="noopener noreferrer">Zum Shop</a>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <GreenCheckbox
                                                checked={wishListEntry.checked}
                                                tabIndex={-1}
                                                onChange={(event) => {
                                                    event.preventDefault();

                                                    const newCheckBoxValue = event.currentTarget.checked;
                                                    setNewCheckboxValue(newCheckBoxValue);

                                                    if (newCheckBoxValue) {
                                                        setEntryCompletedConfirmDialogOpen(true);
                                                    } else {
                                                        checkboxHandler();
                                                    }
                                                }}
                                            />
                                        }
                                        label="Wunsch erfüllt"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {AuthService.isUserLoggedIn() &&
                    <Grid container item direction="column" alignItems="center" justify="flex-start" xs={1}>
                        <IconButton
                            aria-label="actions"
                            aria-controls="entry-actions-menu"
                            aria-haspopup="true"
                            className={classes.itemAction}
                            onClick={(event) => {
                                event.stopPropagation();
                                setAnchorEl(event.currentTarget);
                                setSelectedEntry(wishListEntry);
                                setEntryActionsOpen(true);
                            }}
                        >
                            <MoreVertOutlined/>
                        </IconButton>
                    </Grid>
                    }

                </Grid>
            </Paper>

            <ConfirmDialog
                handleClose={() => {
                    setEntryCompletedConfirmDialogOpen(false);
                }}
                handleConfirm={() => {
                    setEntryCompletedConfirmDialogOpen(false);
                    checkboxHandler();
                }}
                open={entryCompletedConfirmDialogOpen}
                title="Bestätigung erforderlich"
                text="Möchtest du diesen Wunsch als erfüllt markieren?"/>
        </Grid>
        );
}

export default withRouter(WishListEntry);