import React from "react";
import {TextField} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';

const CssTextField = withStyles({
    root: {
        width: "100%",
        '& label.Mui-focused': {
            color: "#89c7b4",
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: "#89c7b4",
            },
            '&:hover fieldset': {
                borderColor: "#9de0cb",
            },
            '&.Mui-focused fieldset': {
                borderColor: "#9de0cb",
            },
            '&.MuiInputBase-input': {
                color: "gray",
            },
        },
    },
})(TextField);

const CustomTextField = (args) => {
    return <CssTextField {...args}/>
};

export default CustomTextField;