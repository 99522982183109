import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import AuthService from "../../service/AuthService";
import CustomTextField from "../ui/CustomTextField";

const useStyles = makeStyles((theme) =>
    createStyles({
        name: {
            marginBottom: theme.spacing(2),
        },
    })
);

const CreateContactDialog = (args) => {
    const {
        open,
        setOpen,
        contactCreated,
        contactEdited,
        contact,
    } = args;
    const classes = useStyles();

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");

    useEffect(() => {
        if (contact) {
            setName(contact.name);
            setAddress(contact.address);
        }
    }, [contact]);

    const handleSubmit = function (event) {
        event.preventDefault();

        fetch("/api/contacts", {
            method: contact ? "PUT" : "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
            body: JSON.stringify({
                id: contact && contact.id,
                name: name,
                address: address,
            }),
        }).then(response => response.json()).then(newContact => {
            setOpen(false);
            if (contact) {
                contactEdited();
            } else {
                contactCreated(newContact);
            }
            setName("");
            setAddress("");
        }).catch((error) => {
            console.error("Could not create a new contact/ edit existing.");
            console.error(error);
        });
    }

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">Neuen Kontakt erstellen</DialogTitle>
            <DialogContent>
                <CustomTextField
                    className={classes.name}
                    autoFocus
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={name}
                    onChange={(event) => {
                        setName(event.currentTarget.value);
                    }}
                />
                <CustomTextField
                    id="address"
                    label="Adresse"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={address}
                    onChange={(event) => {
                        setAddress(event.currentTarget.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false);
                }}>
                    Abbrechen
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateContactDialog;
