import React, {useState} from "react";
import {
    createStyles,
    makeStyles,
    Avatar,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from "@material-ui/core";
import {EditOutlined, HighlightOffOutlined, MoreVertOutlined} from "@material-ui/icons";
import AuthService from "../../service/AuthService";
import CreateContactDialog from "./CreateContactDialog";
import ConfirmDialog from "../ui/ConfirmDialog";

const useStyles = makeStyles((theme) =>
    createStyles({
        contact: {
            margin: theme.spacing(2),
            border: "1px solid lightgray",
            padding: theme.spacing(3),
        },
        cardHeader: {
            padding: "8px 16px",
            cursor: "pointer",
        },
        avatar: {
            color: "white",
            backgroundColor: "#89c7b4",
        },
        contactAction: {
            "&:focus": {
                outline: "none",
            }
        },
        icon: {
            marginRight: theme.spacing(1),
        },
        address: {
            borderTop: "1px solid #89c7b4"
        }
    })
);

const Contact = (args) => {
    const {
        contact,
        all,
        contactEditHandler,
        contactRemovedHandler,
        contactsListId,
        onClick,
        expandedContactId,
    } = args;
    const classes = useStyles();

    const [actionsOpen, setActionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [editContactDialogOpen, setEditContactDialogOpen] = useState(false);
    const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);

    const addressLines = contact.address && contact.address.split("\n");
    const address = addressLines && addressLines.map((line, index) => (
        <Typography key={index} variant="body2" color="textSecondary" component="p">
            {line}
        </Typography>
    ));


    function deleteContact() {
        setActionsOpen(false);
        setDeleteConfirmDialogOpen(false)
        const url = contactsListId ?
            `/api/contactsLists/${contactsListId}/contact/${contact.id}` :
            `/api/contacts/${contact.id}`;
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
        }).then(() => {
            contactRemovedHandler();
        }).catch((error) => {
            console.error("Could not remove contact.");
            console.error(error);
        });
    }

    return (
        <Card>
            <CardHeader
                onClick={onClick}
                className={classes.cardHeader}
                avatar={<Avatar aria-label="contact" className={classes.avatar}>
                    {contact.name && contact.name.length > 0 && contact.name.substring(0, 1)}
                </Avatar>}
                action={
                    <>
                        <IconButton
                            aria-label="actions"
                            aria-controls="actions-menu"
                            aria-haspopup="true"
                            className={classes.contactAction}
                            onClick={(event) => {
                                event.stopPropagation();
                                setAnchorEl(event.currentTarget);
                                setActionsOpen(true);
                            }}
                        >
                            <MoreVertOutlined/>
                        </IconButton>
                        <Menu
                            id="actions-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={actionsOpen}
                            onClose={(event) => {
                                setAnchorEl(null);
                                setActionsOpen(false);
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem key={0} onClick={() => {
                                setActionsOpen(false);
                                setEditContactDialogOpen(true);
                            }}>
                                <EditOutlined className={classes.icon}/>
                                Bearbeiten
                            </MenuItem>
                            <CreateContactDialog
                                open={editContactDialogOpen}
                                setOpen={setEditContactDialogOpen}
                                contact={contact}
                                contactEdited={() => {
                                    contactEditHandler();
                                }}
                            />
                            <MenuItem key={1} onClick={() => {
                                if (contactsListId) {
                                    deleteContact();
                                } else {
                                    setActionsOpen(false);
                                    setDeleteConfirmDialogOpen(true);
                                }
                            }}>
                                <HighlightOffOutlined className={classes.icon}/>
                                {contactsListId ? "Entfernen" : "Löschen"}
                            </MenuItem>
                            <ConfirmDialog
                                handleClose={() => {
                                    setDeleteConfirmDialogOpen(false)
                                }}
                                handleConfirm={deleteContact}
                                open={deleteConfirmDialogOpen}
                                title="Bestätigung erforderlich"
                                text={`Soll der Kontakt '${contact.name}' unwiderruflich gelöscht werden?`}/>
                        </Menu>
                    </>
                }
                title={contact.name}
            />
            {(all || expandedContactId === contact.id) && <CardContent className={classes.address}>
                {address}
            </CardContent>}
        </Card>
    );
};

export default Contact;
