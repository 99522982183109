import React, {useState} from "react";
import {
    Box,
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    makeStyles,
    Typography,
} from "@material-ui/core";
import {DropzoneArea} from "material-ui-dropzone";
import axios from 'axios';

const useStyles = makeStyles((theme) =>
    createStyles({
        dialogContent: {
            "& .MuiDropzoneArea-root": {
                minHeight: 200,
                borderWidth: "2px",
                strokeDasharray: "4 6",
                borderRadius: 0,

                "&.MuiDropzoneArea-active": {
                    animation: "none",
                    backgroundImage: "none",
                },

                "& .MuiDropzoneArea-text": {
                    fontSize: "16px",
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                },

                "& .MuiDropzoneArea-icon": {
                    position: "absolute",
                    width: "40px",
                    height: "40px",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "#89c7b4",
                },

                "& .MuiDropzonePreviewList-root": {
                    paddingLeft: 12,
                    position: "absolute",
                    bottom: "6%",
                },
            },
        },
        uploadMessage: {
            marginTop: theme.spacing(2),
        },
        errorMessage: {
            color: "red",
        },
    })
);

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const AttachmentDialog = (args) => {
    const classes = useStyles(args);
    const {
        open,
        setOpen,
        onAttachmentAdded,
    } = args;

    const [progress, setProgress] = useState(0);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [showDuplicateError, setShowDuplicateError] = useState(false);

    const progressCallback = function (progressEvent) {
        setProgress((progressEvent.loaded / progressEvent.total) * 100);
    };

    const handleUpload = function (selectedFiles) {

        if (selectedFiles.length === 0) {
            return;
        }

        const file = selectedFiles[0];
        setSelectedFileName(file.name);

        const formData = new FormData();
        formData.append("file", file);

        axios
            .post("https://sherdt.de.cool/contacts/a.php", formData, {
                onUploadProgress: progressCallback,
            })
            .then((response) => {
                if (response.data !== 200) {
                    console.log("Could not upload file to lima city", response.data);
                    setShowDuplicateError(true);
                    return;
                }

                setProgress(0);
                setSelectedFileName("");
                setOpen(false);
                onAttachmentAdded(`https://sherdt.de.cool/contacts/images/${file.name}`);
            })
            .catch((e) => {
                console.error("Konnte die Datei nicht hochladen, ", e);
            });
    };

    return (
        <Dialog
            onClose={() => {
                setOpen(false);
                setShowDuplicateError(false);
            }}
            open={open}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">Bild hinzufügen</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DropzoneArea
                    acceptedFiles={["image/*"]}
                    dropzoneText="Drag & drop oder klicken"
                    onChange={(files) => {
                        setShowDuplicateError(false);
                        handleUpload(files);
                    }}
                    showAlerts={true}
                    maxFileSize={4194304}
                    showPreviewsInDropzone={false}
                    filesLimit={1}
                    getFileAddedMessage={(fileName) => {
                        return `Bild '${fileName}' wird hinzugefügt ...`;
                    }}
                    getDropRejectMessage={(rejectedFile) => {
                        return `Das Bild '${rejectedFile.name}' ist zu groß. Maximal zulässige Größe ist '4 MB'.`
                    }}
                />
                {selectedFileName && (
                    <>
                        <div className={classes.uploadMessage}>
                            '{selectedFileName}' wird hochgeladen
                        </div>
                        <LinearProgressWithLabel value={progress}/>
                    </>
                )}
                {showDuplicateError && (
                    <div className={classes.errorMessage}>
                        Eine Datei mit diesem Namen existiert bereits und wird nicht überschrieben. Bitte eine andere Datei verwenden.
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false);
                    setShowDuplicateError(false);
                    setProgress(0);
                    setSelectedFileName("");
                }}>
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AttachmentDialog;
