import {createStyles, makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Footer from "../Footer";
import AuthService from "../../service/AuthService";
import LoadingAnimation from "../ui/LoadingAnimation";
import ContactsListItem from "./ContactsListItem";
import CreateContactsListDialog from "./CreateContactsListDialog";
import Header from "../Header";

const useStyles = makeStyles((theme) =>
    createStyles({
        lists: {
            marginTop: theme.spacing(2),

            "& .list-title": {
                marginLeft: "48px",
            },
        },
    })
);

const Contacts = (args) => {
    const classes = useStyles();

    const {
        history,
    } = args;

    const [contactsLists, setContactsLists] = useState([]);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [isLoadingContactsLists, setIsLoadingContactsLists] = useState(true);

    useEffect(() => {

        if (AuthService.isUserLoggedOut()) {
            console.log("You need to login first, redirecting you to login page.");
            history.push("/login");
            return;
        }

        fetch(`/api/contactsLists`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            }
        }).then(response => response.json())
            .then(data => {
                setContactsLists(data);
                setIsLoadingContactsLists(false);
            });

    }, [history, isLoadingContactsLists]);

    const allLists = contactsLists.map(contactsList => {
        return <ContactsListItem
            deleteHandler={() => {
                setIsLoadingContactsLists(true);
            }}
            key={contactsList.id}
            id={contactsList.id}
            name={contactsList.name}
            history={history}
            />;
    });

    return (
        <>
            <div className="main-content container-fluid">
                <Header src="/contact.png" width="350" height="160"/>
                {isLoadingContactsLists && <LoadingAnimation/>}
                <div className={classes.lists}>
                    <ContactsListItem
                        key={-1} id={""}
                        name="Alle Kontakte"
                        setCreateDialogOpen={setCreateDialogOpen}/>
                    {allLists}
                </div>
            </div>
            <CreateContactsListDialog
                open={createDialogOpen}
                setOpen={setCreateDialogOpen}
                listCreated={setIsLoadingContactsLists}
            />
            <Footer/>
        </>
    );
};

export default Contacts;
