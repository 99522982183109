import React from 'react';

class CenteredMessage extends React.Component {

    render() {
        const {message} = this.props;

        return(
            <div className={"alert-message"}>
                {message}
            </div>
        )
    }
}

export default CenteredMessage;