import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import {Button, Checkbox, FormControlLabel, withStyles} from '@material-ui/core';
import AuthService from "../../service/AuthService";
import CenteredMessage from "../ui/CenteredMessage";
import Footer from "../Footer";

const GreenCheckbox = withStyles({
    root: {
        color: "#9de0cb",
        '&$checked': {
            color: "#89c7b4",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

class WishListEdit extends Component {

    emptyItem = {
        name: '',
        description: '',
        onlyForMe: false
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckboxChanged = this.handleCheckboxChanged.bind(this);

    }

    async componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            const wishlist = await (await fetch(`/api/r/wishlist/${this.props.match.params.id}`)).json();
            this.setState({item: wishlist});
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    handleCheckboxChanged(event) {
        let item = {...this.state.item};
        item.onlyForMe = event.currentTarget.checked;
        this.setState({item});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;

        await fetch('/api/wishlist' + (item.id ? '/' + item.id : ''), {
            method: (item.id) ? 'PUT' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            },
            body: JSON.stringify(item),
        });

        this.props.history.push('/');
    }

    render() {
        if (AuthService.isUserLoggedOut()) {
            return <CenteredMessage message={"Dieser Bereich ist geschützt, bitte erstmal einloggen"}>
            </CenteredMessage>
        }

        const {item} = this.state;
        const title = <h2>{item.id ? 'Wunschliste ändern' : 'Neue Wunschliste'}</h2>;

        return <div>
            <Container className="main-content">
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="name">Name</Label>
                        <Input type="text" name="name" id="name" value={item.name || ''}
                               onChange={this.handleChange} autoComplete="name"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">Beschreibung</Label>
                        <Input type="textarea" name="description" id="description" value={item.description || ''}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={<GreenCheckbox checked={item.onlyForMe} onChange={this.handleCheckboxChanged} name="onlyForMe" />}
                            label="Nur für mich"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button variant="outlined" color="primary" type="submit">Speichern</Button>{' '}
                        <Button variant="outlined" href="/">Abbrechen</Button>
                    </FormGroup>
                </Form>
            </Container>
            <Footer/>
        </div>
    }
}

export default withRouter(WishListEdit);