import React, {Component} from 'react';
import {Container} from 'reactstrap';
import AuthService from "../../service/AuthService";
import Header from "../Header";
import Footer from "../Footer";
import LoadingAnimation from "../ui/LoadingAnimation";
import {Grid, IconButton, Menu, MenuItem} from "@material-ui/core";
import {AddCircleOutline, DeleteOutline, EditOutlined, MoreVertOutlined} from "@material-ui/icons";
import ConfirmDialog from "../ui/ConfirmDialog";

class Wish extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wishLists: [],
            isLoading: true,
            anchorEl: null,
            selectedList: {},
            actionsOpen: false,
            confirmDialogOpen: false,
        };
        this.remove = this.remove.bind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});

        fetch('/api/r/wishlists')
            .then(response => response.json())
            .then(data => this.setState({wishLists: data, isLoading: false}));
    }

    remove(id) {
        fetch(`/api/wishlist/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getUserInfo().token
            }
        }).then(() => {
            let updatedWishLists = [...this.state.wishLists].filter(i => i.id !== id);
            this.setState({wishLists: updatedWishLists});
        });
    }

    render() {
        const {wishLists, isLoading} = this.state;
        if (isLoading) {
            return <LoadingAnimation/>
        }

        return (
            <div>
                <Container fluid className={'main-content'}>
                    <Header src={"/logo-wl.png"} width="350" height="98"/>
                    <Grid container justify="center" alignItems="center">
                        {wishLists.map(wishList => {
                            let resultWishList = <div/>;

                            if (AuthService.isUserLoggedIn() || !wishList.onlyForMe) {
                                resultWishList = <Grid item xs={12} key={wishList.id}>
                                    <div className="wish-list">
                                        <div className="list-title">
                                            <a href={"/wishlist/" + wishList.id}>{wishList.name}</a>
                                            {AuthService.isUserLoggedIn() && (
                                                <IconButton
                                                    aria-label="actions"
                                                    aria-controls="actions-menu"
                                                    aria-haspopup="true"
                                                    className="listItemAction"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        this.setState({anchorEl: event.currentTarget});
                                                        this.setState({selectedList: wishList});
                                                        this.setState({actionsOpen: true});
                                                    }}
                                                >
                                                    <MoreVertOutlined/>
                                                </IconButton>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            }

                            return resultWishList;
                        })}

                        <Menu
                            id="actions-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={this.state.actionsOpen}
                            onClose={(event) => {
                                this.setState({anchorEl: null});
                                this.setState({actionsOpen: false});
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem key={0} onClick={() => {
                                this.setState({actionsOpen: false});
                                this.props.history.push(`/wishlists/${this.state.selectedList.id}`);
                            }}>
                                <EditOutlined fontSize="small" className="actionMenuIcon"/>
                                Bearbeiten
                            </MenuItem>

                            <MenuItem key={1} onClick={() => {
                                this.setState({actionsOpen: false});
                                this.setState({confirmDialogOpen: true});
                            }}>
                                <DeleteOutline className="actionMenuIcon"/>
                                Löschen
                            </MenuItem>
                        </Menu>

                        <ConfirmDialog
                            handleClose={() => {
                                this.setState({confirmDialogOpen: false})
                            }}
                            handleConfirm={() => {
                                this.setState({confirmDialogOpen: false})
                                this.remove(this.state.selectedList.id);
                            }}
                            open={this.state.confirmDialogOpen}
                            title="Bestätigung erforderlich"
                            text={`Soll die Liste mit dem Namen '${this.state.selectedList.name}' unwiderruflich gelöscht werden?`}/>
                    </Grid>


                    {AuthService.isUserLoggedIn() && (
                        <Grid container item justify="center" alignItems="center" xs={12}>
                            <IconButton className="iconButtonNoOutline" onClick={() => {
                                this.props.history.push("/wishlists/new");
                            }}>
                                <AddCircleOutline/>
                            </IconButton>
                        </Grid>
                    )}
                </Container>
                <Footer/>
            </div>
        );
    }
}

export default Wish;