import React, {useEffect, useState} from "react";
import {createStyles, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        label: {
            cursor: "pointer",
            padding: "3px",
        },
        editWrapper: {
            width: "100%",
        },
        input: {
            width: "100%",
        },
    })
);

const Editable = ({
                      childRef,
                      text,
                      type,
                      placeholder,
                      onSave,
                      inputClassName,
                      ...props
                  }) => {

    const classes = useStyles();
    const [isEditing, setEditing] = useState(false);
    const [content, setContent] = useState(text);

    useEffect(() => {
        if (childRef && childRef.current && isEditing === true) {
            childRef.current.focus();
        }
    }, [isEditing, childRef]);

    const handleKeyDown = (event, type) => {
        const {key} = event;
        const keys = ["Escape", "Tab"];
        const enterKey = "Enter";
        const allKeys = [...keys, enterKey]; // All keys array

        if (
            (type === "textarea" && keys.indexOf(key) > -1) ||
            (type !== "textarea" && allKeys.indexOf(key) > -1)
        ) {
            setEditing(false);
            onSave(content);
        }
    };

    return (
        <section {...props}>
            {isEditing ? (
                    <div
                        className={classes.editWrapper}
                        onBlur={() => {
                            setEditing(false);
                        }}
                        onKeyDown={e => handleKeyDown(e, type)}
                    >
                        <input
                            className={`${inputClassName} ${classes.input}`}
                            ref={childRef}
                            type="text"
                            name="name"
                            value={content}
                            onChange={e => {
                                setContent(e.target.value);
                            }}
                            onBlur={() => {
                                onSave(content);
                            }}
                        />
                    </div>
                ) :
                (
                    <div
                        className={classes.label}
                        onClick={() => {
                            setEditing(true);
                        }}
                    >
                      <span>
                        {content || text || "Editable content"}
                      </span>
                    </div>
                )
            }
        </section>
    );
};

export default Editable;