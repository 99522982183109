import React, {Component} from "react";
import {FaHeart} from 'react-icons/fa';

class Footer extends Component {

    render() {
        return <div className={"footer"}>
            &copy; created by #sergejustine with <FaHeart/>
        </div>
    }

}

export default Footer;